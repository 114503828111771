import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as css from './token.module.css';
import { Footer } from '../../../components/Footer';
import { Header } from '../../../components/Header';

const Join = (props: any) => {
  const { t } = useTranslation();
  const { token } = props?.params || {};

  return (
    <main className={css.main}>
      <Header />
      <div className={css.content}>
        <h2>{t('acctionNeedToAcceptInvite')}</h2>

        <div className={css.contentBlock}>
          <div className={css.paragraph}>{t('browserLinkOpened')}</div>
          <h2>{t('youNextActions')}</h2>
          <div className={css.paragraph}>{t('inviteStep1')}</div>
          <div className={css.paragraph}>{t('inviteStep2')}</div>
          <div className={css.paragraph}>{t('inviteStep3')}</div>
          <div className={css.paragraph}>{t('inviteStep4')}</div>
          <div className={css.paragraph}>{t('inviteStep5')}</div>
          <div className={css.paragraph}>{t('inviteStep6')}</div>
          <div className={css.paragraph}>
            {t('inviteStep7')}{' '}
            <a href="mailto:support@copingcard.com">support@copingcard.com</a>
          </div>
          <h2>
            {t('yourInviteToken')} {token}
          </h2>
        </div>
      </div>

      <Footer />
    </main>
  );
};

export default Join;
